import images from 'assets/images/images'
import { ReactElement, useEffect, useRef } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  PageContentResponseType,
  ProductGroupDataType,
  ProductGroupItemType,
  ProductListRootLineItemType,
  SubProductGroupItemType,
} from 'types'
import { LAYOUTS, PAGE_IDS } from 'config/constants'
import { selectStructure, selectTranslations } from 'redux/selector'
import { useAppContext } from 'context/AppContext'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { createProductGroupListPDF } from 'features/NewPDF/ProductGroupListPDF/ProductGroupListPDF'
import Ribbon from 'features/Product/components/Ribbon'
import DCAImage from 'components/DCAImage'
import GroupListDropdown from './GroupListDropdown'
import SubProductGroup from './SubProductGroup'
import 'styles/pages/productGroupList.scss'

type UIProps = {
  data: PageContentResponseType | object
  productGroups: ProductGroupItemType[] | undefined
  subProductGroups: SubProductGroupItemType[] | undefined
  isGroupHasProductFinder: boolean | undefined
  isDropdownAvailable: boolean
  dropdownData: ProductGroupDataType[]
  onGoNexPage: (pageId: number) => void
}

type LayoutConfigType = {
  filter: Array<object>
  productgroupId: number
  ribbon: boolean
  ribbonButtonTarget?: number
}

const ProductGroupListUI = (props: UIProps): ReactElement => {
  const {
    data,
    productGroups,
    subProductGroups,
    dropdownData,
    isDropdownAvailable,
    isGroupHasProductFinder,
    onGoNexPage,
  } = props

  const translations = useSelector(selectTranslations)

  const structure = useSelector(selectStructure)

  const { showModal, hideModal } = useGlobalModalContext()

  const refDropdownProductList = useRef<ProductListRootLineItemType[]>([])
  const {
    setState,
    state: { tourActive },
  } = useAppContext()

  useEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true, stepIndex: 5 })
      }, 300)
    }
  }, [])

  if (!('content' in data) || !productGroups) return <></>

  const layoutData = data.content
  const productGroupListObj = layoutData.find((item) => item.type === LAYOUTS.PRODUCT_GROUPS_LIST)
  const layoutConfig: LayoutConfigType = JSON.parse(productGroupListObj?.config ?? '{}')

  const renderRibbon = (): ReactElement => {
    if (!layoutConfig.ribbon) return <div />

    const ribbonData = productGroups.find(
      (group) => String(group.id) === String(layoutConfig.productgroupId),
    )

    if (!ribbonData) return <div />

    const { name, texts } = ribbonData
    const ribbonTxt = texts.find((text) => text.type === 'header')
    const subHeadline = ribbonTxt?.headline ?? ''
    const description = ribbonTxt?.text ?? ''
    const targetId = layoutConfig.ribbonButtonTarget

    const ribbonDataObj = {
      headline: name,
      subHeadline,
      description,
    }

    const onAddFavorite = () => {
      showModal('FAVORITES_MODAL', {
        showModal: true,
        itemName: name,
        pageContent: data,
        handleClose: hideModal,
      })
    }

    const onExportPDF = (onDone?: () => void) => {
      createProductGroupListPDF(
        {
          productGroups: productGroups,
          subProductGroups: subProductGroups || [],
          pageContent: data,
          structure,
          ribbonData: ribbonDataObj,
          productList: refDropdownProductList.current,
          isFilterProductList: isDropdownAvailable,
          translation: translations,
        },
        onDone,
      )
    }

    return (
      <Ribbon
        pageId={data.pageId}
        {...ribbonDataObj}
        targetId={targetId}
        onAddFavorite={onAddFavorite}
        onExportPDF={onExportPDF}
      />
    )
  }

  const renderSubProductGroups = (id: string): ReactElement => {
    if (!subProductGroups) return <div />

    const currentSubProducts = subProductGroups.filter(
      (obj) => parseInt(obj.parentId) === parseInt(id),
    )

    return <SubProductGroup subProducts={currentSubProducts} onGoNextPage={onGoNexPage} />
  }

  const renderProductGroups = (): ReactElement => {
    if (!productGroups) return <div />

    const listGroup = productGroups.filter((item) => parseInt(item.parentId) === data.pageId)

    if (isGroupHasProductFinder) {
      const PRODUCT_FINDER_DATA: ProductGroupItemType = {
        id: JSON.stringify(PAGE_IDS.PRODUCT_FINDER),
        parentId: JSON.stringify(PAGE_IDS.PRODUCT_FINDER),
        name: translations.product_finder_header || 'Looking for the right product?',
        texts: [
          {
            headline: '',
            text:
              translations.product_finder_description ||
              'Use the Kärcher Product Finder and have the appropriate product displayed based on your selected criteria.',
            type: 'header',
            images: [
              {
                type: 'thumb',
                url: images.productFinder,
              },
            ],
          },
        ],
      }
      listGroup.push(PRODUCT_FINDER_DATA)
    }

    return (
      <Container className='py-3'>
        <Row xs={1} sm={2} md={4}>
          {listGroup.map((item) => {
            const { name, texts, id } = item
            const groupHeader = texts.find((text) => text.type === 'header')
            const { text: description } = groupHeader ?? {}
            const productGroupImageSrc = groupHeader?.images?.find(
              (image) => image.type === 'thumb',
            )

            return (
              <Col key={id}>
                <Card className='card-wrapper'>
                  <div onClick={() => onGoNexPage(parseInt(item.id))}>
                    {productGroupImageSrc && (
                      <DCAImage type='cardImage' variant='top' src={productGroupImageSrc.url} />
                    )}

                    <p className='product-title'>{name}</p>

                    <p className='product-desc'>{description}</p>
                  </div>

                  {renderSubProductGroups(item.id)}
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }

  const renderGroupListDropdown = (): ReactElement => {
    const onProductListUpdated = (productList: ProductListRootLineItemType[]) => {
      refDropdownProductList.current = productList
    }

    return (
      <GroupListDropdown
        dropdownData={dropdownData}
        productGroupId={layoutConfig.productgroupId}
        onProductListUpdated={onProductListUpdated}
      />
    )
  }

  return (
    <div>
      {renderRibbon()}

      {isDropdownAvailable ? renderGroupListDropdown() : renderProductGroups()}
    </div>
  )
}

export default ProductGroupListUI
