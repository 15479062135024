import React from 'react'
import { Col, Container, FormCheck, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType } from 'types'
import { COMMON, PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import { useAppContext } from 'context/AppContext'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import AkTable from 'components/AkTable/AkTable'
import DCAImage from 'components/DCAImage'
import ImagePreview from './ImagesPreview'
import ServicePrices from './ServicePrices'
import Texts from './Texts'

type DescriptionProps = {
  productDetails: ProductDetailItemType
  onExportPDF?: (onDone: () => void) => void
}

const Description = ({ productDetails, onExportPDF }: DescriptionProps): React.ReactElement => {
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const {
    state: { stepIndex, tourActive },
  } = useAppContext()

  const translations = useSelector(selectTranslations)
  const productsCompare = useSelector(selectProductComparison)

  const { pdfLoading, handleExportPDF } = usePDFLoading(onExportPDF)

  const compareTxt = translations.compare ?? 'Compare'
  const exportTxt = translations.export ?? 'Export'

  const isPastProduct = productDetails?.productType === COMMON.PAST_PRODUCT
  const isCompare = !!productsCompare.find((item) => item.id === productDetails.id)

  const techDataTable = (productDetails?.techdata ?? []).map((item) => {
    const tableColLabel = item.label + (item.unit ? ` (${item.unit})` : '')
    return [{ value: tableColLabel }, { value: item.value }]
  })

  const toggleProductCompare = () => {
    onAddOrRemoveProductCompare(productDetails)
  }

  const renderImagePreview = () => (
    <>
      <Row>
        <Col md='4' className='cursor-pointer'>
          <div onClick={toggleProductCompare}>
            <FormCheck
              label={compareTxt}
              checked={isCompare}
              onClick={toggleProductCompare}
              readOnly
            />
          </div>
        </Col>

        {onExportPDF && (
          <Col md='4' className='cursor-pointer' onClick={handleExportPDF}>
            {pdfLoading ? (
              <Spinner style={{ width: 12, height: 12 }} />
            ) : (
              <>
                <i className='karcher-icon' id='onboarding-icon-export'>
                  &#xe914;
                </i>
                <span className='export-text'>{exportTxt}</span>
              </>
            )}
          </Col>
        )}
      </Row>

      <Row className='mt-3'>
        {
          <>
            {productDetails.actionIcons.length > 0 && (
              <Col xs={6} md={2}>
                {productDetails.actionIcons.map((item: any) => {
                  return (
                    <DCAImage
                      src={item.url}
                      style={{ width: 85, marginBottom: 5 }}
                      key={item.text}
                    />
                  )
                })}
              </Col>
            )}
            <Col md={10}>
              <ImagePreview images={productDetails.images} />
            </Col>
          </>
        }
      </Row>
    </>
  )

  const renderTechData = () => {
    const {
      ean,
      partnumberFormatted,
      priceNetFormatted,
      priceGrossFormatted,
      servicePricesFormatted,
      requiresInstruction,
      texts,
    } = productDetails

    const eanCodeTitle = translations.ean_code ?? 'EAN Code:'
    const orderNumberTitle = translations.order_number ?? 'Order Number'
    const priceRemarkTitle =
      translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'
    const grossPriceNumberTitle = translations.gross_priceNumber ?? 'Price Gross'
    const netPriceNumberTitle = translations.net_priceNumber ?? 'Price Net'
    const instructionRequiredTitle =
      translations.instruction_required ?? 'This device requires instruction.'
    const technicalDataTitle = translations.technical_data ?? 'Technical Data'

    const onboardingStyles = stepIndex === 6 && tourActive ? { backgroundColor: 'red' } : {}

    return (
      <>
        {partnumberFormatted && (
          <Row className='techdata-bold'>
            <span id='onboarding-order-number' style={onboardingStyles}>
              {orderNumberTitle}: {partnumberFormatted}
            </span>
          </Row>
        )}
        {ean && (
          <Row className='techdata-bold'>
            <span>
              {eanCodeTitle} {ean}
            </span>
          </Row>
        )}
        {!isPastProduct && (
          <Row className='techdata-bold'>
            {priceNetFormatted && (
              <span>
                {netPriceNumberTitle}: {priceNetFormatted}
              </span>
            )}
            {priceGrossFormatted && (
              <span>
                {grossPriceNumberTitle}: {priceGrossFormatted}
              </span>
            )}
            <p style={{ fontFamily: 'Clan Pro News' }}>{priceRemarkTitle}</p>
          </Row>
        )}
        {!isPastProduct && servicePricesFormatted && (
          <ServicePrices servicePrices={servicePricesFormatted} />
        )}
        {requiresInstruction && (
          <Row className='techdata-bold'>
            <span>{instructionRequiredTitle}</span>
          </Row>
        )}
        {!isPastProduct && texts && <Texts texts={texts} />}
        {!isPastProduct && techDataTable && (
          <Row className='mt-3'>
            <h6 className='techdata-bold'>{technicalDataTitle.toLocaleUpperCase()}</h6>
            <AkTable table={techDataTable} />
          </Row>
        )}
      </>
    )
  }

  return (
    <Container className='description-wrapper pt-4' id={PRODUCT_DETAILS_SECTIONS.DESCRIPTION}>
      <Row>
        <Col md='6'>{renderImagePreview()}</Col>
        <Col md='6'>{renderTechData()}</Col>
      </Row>
    </Container>
  )
}

export default Description
